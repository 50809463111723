 .rocket-landing__container {
   background-color: white;
 }

 .rocket-landing__title {
   font-size: 2.5rem;
 }

 .rocket-landing__wrapper {
   background-image: url(./images/pattern-bg-1.svg), linear-gradient(90deg, rgba(218, 233, 247, 1) 0%, rgba(195, 215, 242, 1) 100%);
   background-blend-mode: lighten;
   background-size: cover;
 }

 .rocket-landing__mini-rocket {
   background: url(./images/mini-rocket.png) no-repeat;
   background-size: contain;
   background-position: center bottom;
 }

 .rocket-container {
   height: 100%;
   background: url(./images/clouds.png) repeat;
 }

 .rocket-container__title {
   color: #7148d3;
 }

 .rocket-container__sub-title {
   color: #422489;
 }

 .tower-crane__container-block {
   background: url(./images/tower-crane.png) repeat-y;
   background-size: contain;
   margin-top: 100px;
   left: -40px;
   z-index: 500;
 }

 .tower-crane__container-block:before {
   position: absolute;
   content: '';
   width: 96px;
   top: -80px;
   height: 116px;
   background: url(./images/tower-crane-top.svg) no-repeat;
   background-size: contain;
 }

 .tower-crane__operator-cab-front-container {
   top: 200px;
   left: -84px;
   z-index: 700;
 }

 .tower-crane__operator-cab-back-container {
   top: 200px;
   left: -84px;
 }

 .tower-crane__operator-cab-front {
   position: relative;
   width: 290px;
   height: 280px;
   background: url(./images/tower-crane-operator-cab.png) no-repeat;
   background-size: contain;
   z-index: 600;
 }

 .tower-crane__operator-cab-back {
   position: absolute;
   content: '';
   width: 160px;
   height: 224px;
   background: url(./images/tower-crane-operator-cab-back.png) no-repeat;
   background-size: contain;
   z-index: 0;
   top: -42px;
 }

 .rocket-body {
   height: 100%;
   background: url(./images/rocket-lauch-building.svg) repeat-y;
   background-size: 720px 680px;
   background-position: center top;
 }

 .rocket-body__top {
   z-index: 500;
   display: block;
   width: 560px;
   background: url(./images/rocket-top.png) no-repeat;
   height: 960px;
   background-size: contain;
   background-position-x: center;
   background-position-y: bottom;
 }

 .rocket-fin__container {
   margin-top: -2200px;
   width: 960px;
 }

 .rocket-fin__block {
   z-index: 100;
   position: relative;
   width: 320px;
   background: url(./images/rocket-wing.png) no-repeat;
   height: 2200px;
   background-size: contain;
   background-position-x: center;
   background-position-y: bottom;
 }

 .rocket-fin-block__left {
   margin-left: 0px;
 }

 .rocket-fin-block__right {
   -moz-transform: scaleX(-1);
   -o-transform: scaleX(-1);
   -webkit-transform: scaleX(-1);
   transform: scaleX(-1);
   filter: FlipH;
   -ms-filter: "FlipH";
   margin-right: 0px;
 }

 .rocket-body__bottom {
   z-index: 700;
   position: relative;
   width: 560px;
   background: url(./images/rocket-bottom.png) no-repeat;
   height: 613px;
   margin-top: -106px;
   background-size: contain;
   background-position-x: center;
   background-position-y: bottom;
 }

 .rocket-body__level {
   z-index: 600;
   position: relative;
   width: 560px;
   background: url(./images/rocket-inner.png) repeat-y;
   height: auto;
   background-size: contain;
   background-position-x: center;
 }

 .rocket-body__level-content {
   position: absolute;
   width: 1200px;
   left: 50%;
   transform: translateX(-50%);
 }

 .rocket-body__level-content-left {
   width: 210px;
   margin-left: 180px;
   background: rgb(195, 215, 242);
   background: linear-gradient(90deg, rgba(195, 215, 242, 1) 0%, rgba(218, 233, 247, 1) 100%);
   border: 1px solid #b5ceea;
 }

 .rocket-body__level-content-right {
   width: 360px;
   position: relative;
   background: rgb(195, 215, 242);
   background: linear-gradient(90deg, rgba(195, 215, 242, 1) 0%, rgba(218, 233, 247, 1) 100%);
   border: 1px solid #b5ceea;
 }

 .rocket-body__level-content-badge {
   top: -56px;
 }

 .rocket-body__level-content-badge-pupilfirst {
   width: 3.5rem;
   top: 50%;
   transform: translateY(-50%);
   margin-left: -50px;
 }

 .rocket-body__level-content-right-number {
   position: absolute;
   background: rgb(229, 240, 254);
   background: linear-gradient(90deg, rgba(229, 240, 254, 1) 0%, rgba(195, 215, 242, 1) 100%);
   left: -38px;
   width: 37px;
 }

 .rocket-body__level-image-container {
   position: relative;
   width: 560px;
   background: url(./images/level-podium.png) no-repeat;
   background-size: contain;
   background-position-x: center;
   min-height: 360px;
   background-position-y: bottom;
 }

 .rocket-body__level-image {
   position: absolute;
   bottom: 0;
   width: 100%;
   left: 50%;
   transform: translateX(-50%);
 }

 .rocket-body__level-image--1 {
   bottom: 40px;
   width: 360px;
 }

 .rocket-body__level-image--2 {
   bottom: 40px;
   width: 240px;
 }

 .rocket-body__level-image--3 {
   bottom: 40px;
   width: 280px;
 }

 .rocket-body__level-image--4 {
   bottom: 2px;
   width: 300px;
 }

 .rocket-body__level-image--5 {
   bottom: 40px;
   width: 350px;
 }

 .rocket-body__level-image--6 {
   bottom: 40px;
   width: 200px;
 }

 .rocket-body__level-image--7 {
   bottom: 40px;
   width: 240px;
 }

 .rocket-body__level-image--8 {
   bottom: 40px;
   width: 270px;
 }

 .rocket-body__level-image--9 {
   bottom: 40px;
   width: 260px;
 }

 .rocket-body__level-image--10 {
   bottom: 40px;
   width: 320px;
 }

 .rocket-flame__container {
   width: 1010px;
   height: 360px;
   position: relative;
   z-index: 800;
 }

 .rocket-flame__block {
   z-index: 100;
 }

 .rocket-flame__block--middle {
   left: 5px;
 }

 .rocket-flame__animate {
   margin: 10px auto 240px;
   width: 60px;
   height: 60px;
   position: relative;
   transform-origin: center top;
   animation-name: flicker;
   animation-duration: 3ms;
   animation-delay: 200ms;
   animation-timing-function: ease-in;
   animation-iteration-count: infinite;
   animation-direction: alternate;
 }

 .rocket-flame__block-img {
   width: 400px;
   z-index: 800;
   position: absolute;
   top: -170px;
   left: -170px;
 }

 .rocket-flame__flame {
   bottom: 0;
   position: absolute;
   border-bottom-right-radius: 50%;
   border-bottom-left-radius: 50%;
   border-top-left-radius: 50%;
   transform: rotate(135deg) scale(3, 3);
   -moz-transform: rotate(135deg) scale(3, 3);
   -webkit-transform: rotate(135deg) scale(3, 3);
   -o-transform: rotate(135deg) scale(3, 3);
 }

 .rocket-flame__block--middle .rocket-flame__flame {
   transform: rotate(135deg) scale(2.25, 2.25);
   -moz-transform: rotate(135deg) scale(2.25, 2.25);
   -webkit-transform: rotate(135deg) scale(2.25, 2.25);
   -o-transform: rotate(135deg) scale(2.25, 2.25);
 }

 .rocket-flame__flame-yellow {
   left: 15px;
   width: 30px;
   height: 30px;
   background: gold;
   box-shadow: 0px 0px 9px 4px gold;
 }

 .rocket-flame__flame-orange {
   left: 10px;
   width: 40px;
   height: 40px;
   background: orange;
   box-shadow: 0px 0px 9px 4px orange;
 }

 .rocket-flame__flame-red {
   left: 5px;
   top: 40px;
   width: 50px;
   height: 50px;
   background: OrangeRed;
   border: 1px solid OrangeRed;
   box-shadow: 0px 0px 5px 4px OrangeRed;
 }

 .rocket-flame__flame-white {
   left: 15px;
   top: 20px;
   width: 30px;
   height: 30px;
   background: white;
   box-shadow: 0px 0px 9px 6px white;
 }

 .rocket-flame__flame-circle {
   border-radius: 50%;
   position: absolute;
 }

 .rocket-flame__flame-blue {
   width: 10px;
   height: 10px;
   left: 25px;
   top: 5px;
   background: SlateBlue;
   box-shadow: 0px 0px 15px 10px #4fc1e9;
 }

 .rocket-flame__flame-white-mask {
   width: 40px;
   height: 40px;
   left: 10px;
   top: -30px;
   background: #ffffff;
   border: 1px solid #ffffff;
   box-shadow: 0px 0px 10px 10px #ffffff;
 }

 @keyframes flicker {
   0% {
     transform: rotate(-1deg);
     -moz-transform: rotate(-1deg);
     -webkit-transform: rotate(-1deg);
   }
   20% {
     transform: rotate(1deg);
     -moz-transform: rotate(1deg);
     -webkit-transform: rotate(1deg);
   }
   40% {
     transform: rotate(-1deg);
     -moz-transform: rotate(-1deg);
     -webkit-transform: rotate(-1deg);
   }
   60% {
     transform: rotate(1deg) scaleY(1.04);
   }
   80% {
     transform: rotate(-2deg) scaleY(0.92);
     -moz-transform: rotate(-2deg) scaleY(0.92);
     -webkit-transform: rotate(-2deg) scaleY(0.92);
   }
   100% {
     transform: rotate(1deg);
     -webkit-transform: rotate(1deg);
     -moz-transform: rotate(1deg);
   }
 }